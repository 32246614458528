.flex {
  display: flex;
}

.flex.horizontal {
  align-items: center;
}

.flex.vertical {
  flex-direction: column;
}

.flex.sparsed {
  justify-content: space-between;
}

/* Horizontal gap. */
.flex.gap-sm.horizontal > .item + .item {
  margin-left: var(--ant-padding-xs);
}

.flex.gap-md.horizontal > .item + .item {
  margin-left: var(--ant-padding-md);
}

.flex.gap-lg.horizontal > .item + .item {
  margin-left: var(--ant-padding-lg);
}

.flex.gap-xl.horizontal > .item + .item {
  margin-left: var(--ant-padding-xl);
}

/* Vertical gap. */
.flex.gap-sm.vertical > .item + .item {
  margin-top: var(--ant-padding-xs);
}

.flex.gap-md.vertical > .item + .item {
  margin-top: var(--ant-padding-md);
}

.flex.gap-lg.vertical > .item + .item {
  margin-top: var(--ant-padding-lg);
}

.flex.gap-xl.vertical > .item + .item {
  margin-top: var(--ant-padding-xl);
}
